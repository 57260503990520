import React from 'react';
import { Link } from 'react-router-dom';
import image1 from '../../../images/image1.jpg';

const HomePage = () => {
    return (
        <div style={{ padding: '20px' }}>
            <h1 style={{ textAlign: 'center', color: '#333' }}>Welcome to BlossomUp</h1>
            <img src={image1} alt="Orchids" style={{ width: '100%', maxWidth: '100%', height: 'auto' , marginTop: '10px'}} />
            <p style={{ textAlign: 'center', color: '#666', fontSize: '18px', padding: '20px 0' }}>
                Explore our collection of beautiful orchids and learn more about their care.
                We are conducting research on orchid plant diseases, and you can contribute by uploading information about new orchid leaf diseases.
            </p>

            <div style={{ textAlign: 'center' }}>
                <Link to="/submit-images" style={{ textDecoration: 'none' }}>
                    <button style={{ backgroundColor: '#007bff', color: '#fff', padding: '10px 20px', borderRadius: '5px', border: 'none' }}>Add new Disease</button>
                </Link>
            </div>

        </div>
    );
};

export default HomePage;
