import React from 'react';
import { Routes, Route } from 'react-router-dom';
import UploadNewDiseaseImages from './Pages/Image_Identification/home/UploadNewDiseaseImages';
import HomePage from './Pages/Image_Identification/home/HomePage';

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/submit-images" element={<UploadNewDiseaseImages />} />
    </Routes>
  );
}
export default App;
