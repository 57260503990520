import UploadImageform from "../../farmer/SubmitImages.jsx";

const UploadNewDiseaseImages = () => {
  return (
        <UploadImageform />
  )
}

export default UploadNewDiseaseImages


